<template>
	<div class="main">
		<div class="center_form">
			<div class="cf_left"></div>
			<div class="cf_right">
				<i class="login_form_back"></i>
				<div id="tips">登录</div>
				<a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
					<a-form-item>
						<a-input size="large" type="text" placeholder="账户" 
						v-decorator="['username',{rules: [{ required: true, message: '请输入帐户名' }], validateTrigger: 'change'}]">
							<a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
						</a-input>
					</a-form-item>
					<a-form-item>
						<a-input-password size="large" placeholder="密码" 
						v-decorator="['password', {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}]">
							<a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
						</a-input-password>
					</a-form-item>
					<a-row :gutter="16">
						<a-col class="gutter-row" :span="14">
							<a-form-item>
								<a-input size="large" type="text" placeholder="验证码" 
									v-decorator="['code', {rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur'}]" >
									<a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }" />
								</a-input>
							</a-form-item>
						</a-col>
						<a-col class="gutter-row" :span="8">
							<img :src="codeUrl" alt="" @click="getCodeImg" style="cursor:pointer;">
						</a-col>
					</a-row>
					<a-form-item style="margin-top:24px">
						<a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="state.loginBtn" :disabled="state.loginBtn">确定</a-button>
					</a-form-item>
				</a-form>
				
				<!-- 修改密码 -->
				<a-modal style="top: 20px;" :width="480" v-model="visible" @ok="handleOk" class="modal_foot_center" :closable="false">
					<div style="display:flex;flex-direction: column;align-items: center;margin-bottom:20px;">
						<img src="../../assets/images/ex_tips.png" alt="" width="48px">
						<div style="font-size:14px;margin-top:10px;">您的密码已过期,请修改密码</div>
					</div>
					<a-form class="permission-form" :form="form1" labelAlign="left">
						<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="新密码">
							<a-input type="password" placeholder="请输入密码"  @change="getPwd"
								v-decorator="['password1',{ rules: [
									{ required: true, message: '请输入密码!'},
									{ min: 6, message: '密码长度不低于6位'}
								]}]" 
							/>
						</a-form-item>
						<div class="input_span">
							<label style="margin-left:69px;"></label>
							<span id="one"></span>
							<span id="two"></span>
							<span id="three"></span>
						</div>
						<div id="font">
							<span>弱</span>
							<span>中</span>
							<span>强</span>
						</div>
						<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="确认密码">
							<a-input type="password" placeholder="请输入确认密码"
								v-decorator="['password2',{ rules: [
									{ required: true, message: '请输入密码!'},
									{ min: 6, message: '密码长度不低于6位'}
								]}]" 
							/>
						</a-form-item>
					</a-form>
				</a-modal>
			</div>
		</div>
	</div>
</template>

<script>
	import md5 from 'md5'
	import { mapActions } from 'vuex'
	import { timeFix } from '@/utils/util'
	import { hostUrl,encrypt } from '@/utils' 
	import { changePwd } from '@/api/login'
	import storage from 'store'
	import { ACCESS_TOKEN } from '@/store/mutation-types'
	export default {
		components: {  },
		data() {
			return {
				loginBtn: false,
				loginType: 0,
				isLoginError: false,
				requiredTwoStepCaptcha: false,
				stepCaptchaVisible: false,
				form: this.$form.createForm(this),
				state: {
					time: 60,
					loginBtn: false,
					loginType: 0,
					smsSendBtn: false,
				},
				codeUrl: hostUrl+'/newAdmin.php?c=Login&a=varify_code',
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 16
					}
				},
				visible: false,
				form1: this.$form.createForm(this),
				opwd:'',
			}
		},
		created() {
			
		},
		methods: {
			getCodeImg() {
                if(this.codeUrl.indexOf('&v=')==-1){
                    this.codeUrl = this.codeUrl + '&v=' + Date.parse(new Date());
                }else{
                    this.codeUrl = this.codeUrl.split('&v=')[0]+'&v=' + Date.parse(new Date());
                }
			},
			...mapActions(['Login', 'Logout']),
			handleSubmit(e) {
				e.preventDefault()
				const {
					form: {
						validateFields
					},
					state,
					Login
				} = this
				state.loginBtn = true
				const validateFieldsKey = ['username', 'password', 'code'] 
				validateFields(validateFieldsKey, {
					force: true
				}, (err, values) => {
					if (!err) {
						//console.log('login form', values)
						const loginParams = { ...values }
						delete loginParams.username
						loginParams['username'] = values.username
						loginParams.password = md5(values.password)
						this.opwd = values.password
						loginParams.code = values.code
                        let encode = `username=${loginParams.username}&password=${loginParams.password}&code=${loginParams.code}`;
                        encode = encrypt(encode);
						Login({encode_data:encode})
							.then((res) => this.loginSuccess(res))
							.catch(err => this.requestFailed(err))
							.finally(() => {
								state.loginBtn = false
							})
					} else {
						setTimeout(() => {
							state.loginBtn = false
						}, 600)
					}
				})
			},
			loginSuccess(res) {
				//console.log('-->',res)
				this.$router.push({
					path: '/'
				})
				// 延迟 1 秒显示欢迎信息
				setTimeout(() => {
					this.$notification.success({
						message: '欢迎',
						description: `${timeFix()}，欢迎回来`
					})
				}, 1000)
				this.isLoginError = false
			},
			requestFailed(err) {
				//console.log('err',err)
				if(err.code==2017){   //密码过期
					storage.set(ACCESS_TOKEN, err.data.token, 7 * 24 * 60 * 60 * 1000);
					this.visible = true;
				}else{
					this.isLoginError = true
					this.$notification['error']({
						message: '错误',
						description: err || '请求出现错误，请稍后再试',
						duration: 4
					})
				}
				this.getCodeImg();
			},
			handleOk(e) {
				e.preventDefault()
				this.form1.validateFields((err, values) => {
					// console.log(err, values)
					let params = {
						old_password:this.opwd,
						password:values.password2,
					}
					if(values.password1!=values.password2){
						this.$message.error('2次密码不一致');
						return
					}
					changePwd(params).then(res =>{
						if(res.code==200){
							this.$message.success(res.msg)
							this.visible = false
							this.resetForm();
							this.getCodeImg();
							this.$nextTick(()=>{
                                this.form.setFieldsValue({
                                "password":'',
                                "code":''
                                })
							})
							storage.set(ACCESS_TOKEN, '', 7 * 24 * 60 * 60 * 1000);
						}else{
							this.$message.error(res.msg)
							storage.set(ACCESS_TOKEN, '', 7 * 24 * 60 * 60 * 1000);
						}
					}).catch(res =>{})
					
				})
			},
			resetForm(){
				this.uid = ''
				this.$nextTick(()=>{
				    this.form1.setFieldsValue({
				        "password2":'',
				        "password1":''
				    })
				})
			},
			getPwd(e){
				// console.log('e.target.value',e.target.value);
				this.pwdee = e.target.value;
				let newname = e.target.value;
				this.msgText = this.checkStrong(newname);
				if (this.msgText > 1 || this.msgText == 1) {
					document.getElementById("one").style.background = "red";
				} else {
					document.getElementById("one").style.background = "#eee";
				}
				if (this.msgText > 2 || this.msgText == 2) {
					document.getElementById("two").style.background = "orange";
				} else {
					document.getElementById("two").style.background = "#eee";
				}
				if (this.msgText == 4) {
					document.getElementById("three").style.background = "#00D1B2";
				} else {
					document.getElementById("three").style.background = "#eee";
				}
			},
			checkStrong(sValue) {
			      var modes = 0;
			      //正则表达式验证符合要求的
			      if (sValue.length < 1) return modes;
			      if (/\d/.test(sValue)) modes++; //数字
			      if (/[a-z]/.test(sValue)) modes++; //小写
			      if (/[A-Z]/.test(sValue)) modes++; //大写
			      if (/\W/.test(sValue)) modes++; //特殊字符
			      //逻辑处理
			      switch (modes) {
			        case 1:
			          return 1;
			          break;
			        case 2:
			          return 2;
			          break;
			        case 3:
			        case 4:
			          return sValue.length < 4 ? 3 : 4;
			          break;
			      }
			      return modes;
			},
		}
	}
</script>

<style lang="less" scoped>
	#formLogin{
		width: 80%;
		margin-left: 10%;
		margin-right: 10%;
	}
	.user-layout-login {
		label {
			font-size: 14px;
		}

		.getCaptcha {
			display: block;
			width: 100%;
			height: 40px;
		}

		.forge-password {
			font-size: 14px;
		}

		button.login-button {
			padding: 0 15px;
			font-size: 16px;
			height: 40px;
			width: 100%;
		}

		.user-login-other {
			text-align: left;
			margin-top: 24px;
			line-height: 22px;

			.item-icon {
				font-size: 24px;
				color: rgba(0, 0, 0, 0.2);
				margin-left: 16px;
				vertical-align: middle;
				cursor: pointer;
				transition: color 0.3s;

				&:hover {
					color: #1890ff;
				}
			}

			.register {
				float: right;
			}
		}
	}
	.contain{
		width: 100%;
		min-height: 100%;
		opacity: 0.7;
		background-image: linear-gradient(173deg, #CDEAFF 0%, #9BCEFF 100%);
		position: relative;
		.login_back{
			width: 100%;
			height: 309px;
			background: url(~@/assets/images/login_back.png) no-repeat;
			background-size: 100% 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index:1;
		}
		.center_form{
			width: 673px;
			height: 434px;
			position: absolute;
			z-index: 2;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			display:flex;
			justify-content: space-between;
			box-shadow: 0px 2px 8px #d2caca;
			.cf_left{
				width: 333px;
				min-width: 333px;
				height: 100%;
				background: url(~@/assets/images/login_form_left.png) no-repeat;
				background-size: 100%;
			}
			.cf_right{
				width: 340px;
				height: 100%;
				position: relative;
				background: #fff;
				.login_form_back{
					display: inline-block;
					background: url(~@/assets/images/login_form_back.png) no-repeat;
					background-size: 100%;
					width: 176px;
					height: 134px;
					position: absolute;
					top: 0;
					right: 0;
				}
				#tips{
					font-size:24px;
					font-family:PingFangSC-Medium,PingFang SC;
					font-weight:500;
					color:rgba(66,66,66,1);
					text-align: center;
					margin:40px auto;
					position: relative;
					z-index:2;
				}
			}
		}
	}
</style>


<style scoped>
.input_span span {
  display: inline-block;
  width: 98px;
  height: 8px;
  background: #eee;
  line-height: 20px;
  margin-top:-15px;
}

#one {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0px solid;
  margin-left: 20px;
  margin-right: 3px;
}

#two {
  border-left: 0px solid;
  border-right: 0px solid;
  margin-left: -5px;
  margin-right: 3px;
}

#three {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px solid;
  margin-left: -5px;
}
#font span{
	font-size: 12px;
}
#font span:nth-child(1) {
  color: red;
  margin-left: 88px;
}
#font span:nth-child(2) {
  color: orange;
  margin: 0 128px;
}
#font span:nth-child(3) {
  color: #00d1b2;
}
</style>